export const ABTEST_COOKIE_NAME = 'abTestingPopulation';
export const ABTEST_DURATION_DAYS = 1 / 24; /* 1 hour in days */
export const ABTEST_STORE_COOKIE_NAME = 'abTestPopulationStore';
export const AFFILIATION = 'Affiliation';
export const BANNER = 'Banner';
export const BRAND_ORANGE = 'orange';
export const BRAND_CANALBOX = 'canalbox';
export const BRAND_AFRIQUE = 'cplusafrique';
export const BRAND_CALEDONIE = 'cpluscaledonie';
export const BRAND_CARAIBES = 'cpluscaraibes';
export const BRAND_ETHIOPIA = 'cplusethiopia';
export const BRAND_HAITI = 'cplushaiti';
export const BRAND_MADAGASCAR = 'cplusmadagascar';
export const BRAND_MAURICE = 'cplusmaurice';
export const BRAND_REUNION = 'cplusreunion';
export const BRAND_MYANMAR = 'cplusmyanmar';
export const BRAND_MMC = 'ma-maison-canalbox';
export const BRAND_MYCANAL = 'mycanal';
export const BRAND_TIMVISION = 'timvision';
export const BRAND_TNTSAT = 'tntsat';
export const BRAND_TVCARAIBES = 'tvcaraibes';
export const COOKIE_NAME_ADS = 'adsAllowed';
export const COOKIE_NAME_ANONYMOUS = 'anonymousAllowed';
export const COOKIE_NAME_DEVICE_ID = 'deviceId';
export const COOKIE_NAME_SESSION_ID = 'sessionId';
export const COOKIE_NAME_STATS = 'statsAllowed';
export const CPLUS_DOMAINS = 'CANAL+ domains';
export const DATA_API_DOMAIN = 'data.api.canalplus.com';
export const DATA_API_DOMAIN_TIMVISION = 'data.api.timvision.it';
export const DATA_API_DOMAIN_TV = 'data.api.tv.canalplus.com';
export const DATA_API_DOMAIN_ORANGE = 'canal.vct.pgw.orange.fr/data-tracking';
export const DATA_DOMAIN = 'data.canalplus.com';
export const DATA_DOMAIN_AFRIQUE = 'data.canalplus-afrique.com';
export const DATA_DOMAIN_CALEDONIE = 'data.canalplus-caledonie.com';
export const DATA_DOMAIN_CANALBOX = 'data.canalbox.com';
export const DATA_DOMAIN_CARAIBES = 'data.canalplus-caraibes.com';
export const DATA_DOMAIN_ETHIOPIA = 'data.canalplus-ethiopia.com';
export const DATA_DOMAIN_HAITI = 'data.canalplus-haiti.com';
export const DATA_DOMAIN_MADAGASCAR = 'data.canalplus-madagascar.com';
export const DATA_DOMAIN_MAURICE = 'data.canalplus-maurice.com';
export const DATA_DOMAIN_MYANMAR = 'data.canalplus-myanmar.com';
export const DATA_DOMAIN_REUNION = 'data.canalplus-reunion.com';
export const DATA_DOMAIN_TIMVISION = 'data.timvision.it';
export const DATA_DOMAIN_TNTSAT = 'data.tntsat.tv';
export const DATA_DOMAIN_TV = 'data.tv.canalplus.com';
export const DATA_DOMAIN_TVCARAIBES = 'data.tvcaraibes.tv';
export const DATA_DOMAIN_ORANGE = 'canal.vct.pgw.orange.fr/trackingtool';
/** default cookie duration is 6 months */
export const DEFAULT_COOKIE_DURATION = 182;
/** device id cookie duration is 13 months */
export const DEVICE_ID_COOKIE_DURATION = 395;
export const DIRECT = 'Direct';
export const DISPLAY = 'Display';
export const EMAIL = 'Email';
export const IFRAME_ID = 'pm-cookie-sync-frame';
export const IFRAME_PATH_LOCAL = '/v2/cookie-sync.html';
export const IFRAME_PATH_PROD = '/production/v2/cookie-sync.html';
export const INTERN_CAMPAIGN = 'Intern campaign';
export const NATURAL_SEARCH = 'Natural Search';
export const OPEN_PARTNER = 'Open partner';
export const OTHER_REF_DOMAINS = 'Other referring domains';
export const PAID_SEARCH = 'Paid search';
export const PARTNERSHIP = 'Partnership';
export const PATH_CANALBOX_LOCAL = '/v2/assets/icons/canalbox.svg';
export const PATH_CANALBOX_PROD = '/production/v2/assets/icons/canalbox.svg';
export const PATH_MMC_LOCAL = '/v2/assets/icons/mmc.svg';
export const PATH_MMC_PROD = '/production/v2/assets/icons/mmc.svg';
export const PATH_PANTON_FONT_LOCAL = '/v2/assets/fonts/PantonItalic.otf';
export const PATH_PANTON_FONT_PROD =
  '/production/v2/assets/fonts/PantonItalic.otf';
export const PATH_TVCARAIBES_LOCAL = '/v2/assets/icons/tvcaraibes.svg';
export const PATH_TVCARAIBES_PROD =
  '/production/v2/assets/icons/tvcaraibes.svg';
export const PUSH_NOTIF = 'Push notification';
export const SESSION_ID_DURATION_DAYS = 1 / 24 / 2; /* 30 minutes */
export const SMS = 'SMS';
export const SOCIAL_NETWORK = 'Social Network';
export const SOCIAL_ORGANIC = 'Organic Social';
export const SOCIAL_PAID = 'Paid Social';
export const THEME_CANALBOX = 'canalbox';
export const THEME_DEFAULT = 'default';
export const THEME_MMC = 'mmcanalbox';
export const THEME_TIMVISION = 'telecomitalia';
export const THEME_TVCARAIBES = 'tvcaraibes';
export const TV_BRANDS = [
  'lgtv',
  'playstation',
  'stb',
  'philipstv',
  'samsungtv',
  'firetvreact',
  'g10',
  'g10lcpi',
  'g11',
  'g9',
  'g9lcpi',
  'g9lcpisat',
  'g9mini',
  'g9sat',
  'hisensetv',
  'sfr',
  'upc',
];

export const VENDORLIST_PATH_LOCAL = '/v2/vendorList.json';
export const VENDORLIST_PATH_PROD = '/production/v2/vendorList.json';
export const VIDEO = 'Video';
export const PAY_VOD = 'pay vod';
export const LAUNCH_APP = 'launch app';
