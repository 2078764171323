import { UAParser } from 'ua-parser-js';
import {
  ABTEST_COOKIE_NAME,
  ABTEST_STORE_COOKIE_NAME,
  AFFILIATION,
  BANNER,
  CPLUS_DOMAINS,
  DIRECT,
  DISPLAY,
  EMAIL,
  INTERN_CAMPAIGN,
  NATURAL_SEARCH,
  OPEN_PARTNER,
  OTHER_REF_DOMAINS,
  PAID_SEARCH,
  PARTNERSHIP,
  PUSH_NOTIF,
  SMS,
  SOCIAL_NETWORK,
  SOCIAL_ORGANIC,
  SOCIAL_PAID,
  VIDEO,
} from '../PrivacyManager/Constants';
import {
  getAllQueryParams,
  getQueryParam,
  isPassAuthenticated,
  readCookie,
} from '../Utils';
import {
  getAttributions,
  setCampaignInLocalStorage,
  syncTrackingCampaignToIframe,
} from '../PrivacyManager/campaignHelpers';

/**
 * Enriches the tracking data layer with various information including environment, session, device,
 * and campaign data. Merges custom tracking data as needed.
 *
 * @param {Object} store - Redux store to access the application state.
 * @param {Object} trackingObject - Custom tracking data that needs to be merged into the data layer.
 */ export const enrichDataLayer = (store, trackingObject = { data: {} }) => {
  const state = store.getState();

  ttDataLayer.add('anonymousTracking', 'true');
  ttDataLayer.add('environment', state.config.environment);

  // Override pageName (and therefore page_name) definition if there isn't any
  if (!ttDataLayer.get('pageName')) {
    if (trackingObject.data.pageName) {
      ttDataLayer.add('pageName', trackingObject.data.pageName);
    } else {
      ttDataLayer.add('pageName', trackingObject.name);
    }
  }

  // If there are props and eVar to override
  // Override channel definition if provided
  if (trackingObject.data && trackingObject.data.channel) {
    ttDataLayer.add('channel', trackingObject.data.channel);
  }

  if (state.VID && state.VID !== '') {
    ttDataLayer.add('device_key', state.VID);
  }

  if (state.trackingKey && state.trackingKey !== '') {
    ttDataLayer.add('tracking_key', state.trackingKey);
  }

  if (state.sessionId && state.sessionId !== '') {
    ttDataLayer.add('sessionId', state.sessionId);
    // Copy of the session attribute because we want to unify the attribute name with the mobile
    ttDataLayer.add('id_session_app', state.sessionId);
  }

  if (ttDataLayer.isFirstEventSinceSessionStart()) {
    ttDataLayer.add('session_start', true);
  }

  // Change pageURL manually because of SPA.
  // But we don't want to overide a previously defined url.
  // For example, the app might have stacked couple of pageView before the tracking tool loads and fires them.
  // All the events would have the same url if we override it here.
  if (window && window.location && !ttDataLayer.get('pageURL')) {
    ttDataLayer.add('pageURL', window.location.href);
  }

  addSearchInfo(ttDataLayer, trackingObject);

  // Add authent info on the dataLayer
  addPassInfo(store, ttDataLayer);

  // Add custom infos about the browser
  addBrowserInfo(ttDataLayer);

  // Add custom props & evar into the dataLayer
  ttDataLayer.mergeObjectInDataLayer(trackingObject.data);

  // Add campaign and referrer data
  addReferrerAndCampaignSource(store, {
    hostname: window.location.hostname,
    ...getAllQueryParams(window.location.search),
    ...state.campaignData,
  });

  // Add custom infos based on previous datalayer.
  // Need to be AFTER merge custom props & eVar because it uses them.
  addCustomInfo(store, ttDataLayer);
};

// We need to specifictly fill the dataLayer for the first event after the library has loaded
export const initDataLayerAfterLoad = ({ store, pageURL }) => {
  const state = store.getState();
  ttDataLayer.setFirstEventSinceLoad();
  ttDataLayer.add('pageURL', pageURL);
  ttDataLayer.add('referrer_type', DIRECT); // No referrer, no campaign so it should be direct
  addReferrerAndCampaignSource(store, {
    hostname: window.location.hostname,
    ...getAllQueryParams(window.location.search),
    ...state.campaignData,
  });
};

/*
 * Source tracking (campaign & referrer)
 */
const addReferrerAndCampaignSource = (store, queries) => {
  const referrer = queries.origref ? queries.origref : document.referrer; // Referrer's override
  let referringDomain = '';

  // Add referrer infos
  if (referrer !== '') {
    ttDataLayer.add('referrer', referrer);
    try {
      // Because IE 11 doesn't support new URL
      referringDomain = new URL(referrer).hostname;

      // Sometimes, the referrer is the site itself so we reset it to avoid counting it as a true external referring domain
      if (referringDomain.indexOf(queries.hostname) > -1) {
        referringDomain = '';
      }

      ttDataLayer.add('referring_domain', referringDomain);
    } catch (e) {
      console.log(e);
    }
  }

  // Get all query parameters
  const partners = getQueryParam('aff', queries.aff); // Legacy affiliation, should be called partners
  let campaignAttributionType = '';
  let campaign = '';
  let campaignThematic = '';
  let campaignEntity = '';

  const campaignData = extractCampaignDataFromQueryParams(queries);
  campaign = campaignData.name ? campaignData.name : ''; // LEGACY => default value for campaign is simply the name

  // `cmp` fields are deprecated but we're keeping them for retrocompatibility. `utm` fields will replace them.
  const cmpChannel = campaignData.channel;

  if (cmpChannel && campaignData.entity) {
    // mandatory field
    ttDataLayer.add('cmp_channel', campaignData.channel);
    ttDataLayer.add('cmp_entity', campaignData.entity);
    ttDataLayer.add('cmp_type', campaignData.campaignType);
    ttDataLayer.add('cmp_thematic', campaignData.thematic);
    ttDataLayer.add('cmp_target', campaignData.target);
    ttDataLayer.add('cmp_date', campaignData.date);
    ttDataLayer.add('cmp_name', campaignData.name);
    ttDataLayer.add('cmp_support', campaignData.support);
    ttDataLayer.add('cmp_crea', campaignData.crea);
    ttDataLayer.add('cmp_placement', campaignData.placement);

    const {
      entity,
      campaignType,
      thematic,
      target,
      date,
      name,
      support,
      crea,
      placement,
    } = campaignData;
    const campaignParts = [
      entity,
      cmpChannel,
      campaignType,
      thematic,
      target,
      date,
      name,
      support,
      crea,
      placement,
    ];

    campaign = campaignParts
      .filter((part) => part) // Remove undefined or empty parts
      .join(':');

    campaignThematic = campaignData.thematic || '';
    campaignEntity = campaignData.entity || '';
  }

  const utmMedium = ttDataLayer.get('utm_medium') || queries.utm_medium;
  const utmSource = ttDataLayer.get('utm_source') || queries.utm_source;
  const utmContent = ttDataLayer.get('utm_content') || queries.utm_content;
  const utmCampaign = ttDataLayer.get('utm_campaign') || queries.utm_campaign;

  if (utmMedium) {
    ttDataLayer.add('utm_medium', utmMedium);
  }

  if (utmSource) {
    ttDataLayer.add('utm_source', utmSource);
  }

  if (utmContent) {
    ttDataLayer.add('utm_content', utmContent);
  }

  if (utmCampaign) {
    ttDataLayer.add('utm_campaign', utmCampaign);
  }

  // Email -------------------------------------------
  if (
    cmpChannel === 'EMAIL' ||
    utmMedium === 'EMAIL' ||
    'sc_cmpid' in queries
  ) {
    ttDataLayer.add('referrer_type', EMAIL);
    campaignAttributionType = EMAIL;

    // Affiliation ------------------------------------------------
  } else if (
    cmpChannel === 'AFFILIATE' ||
    utmMedium === 'AFFILIATE' ||
    'clkid' in queries
  ) {
    ttDataLayer.add('referrer_type', AFFILIATION);
    campaignAttributionType = AFFILIATION;

    // Display campaign ------------------------------------------------------
  } else if (
    cmpChannel === 'DISPLAY' ||
    utmMedium === 'DISPLAY' ||
    'cpmid' in queries
  ) {
    ttDataLayer.add('referrer_type', DISPLAY);
    campaignAttributionType = DISPLAY;

    // Paid search (SEM) -------------------------------------------
  } else if (
    cmpChannel === 'PAID_SEARCH' ||
    utmMedium === 'PAID_SEARCH' ||
    cmpChannel === 'SEA' ||
    utmMedium === 'SEA' ||
    'kwid' in queries
  ) {
    ttDataLayer.add('referrer_type', PAID_SEARCH);
    campaignAttributionType = PAID_SEARCH;

    // SMS ---------------------------------------------
  } else if (cmpChannel === 'SMS' || utmMedium === 'SMS') {
    ttDataLayer.add('referrer_type', SMS);
    campaignAttributionType = SMS;

    // Video ---------------------------------------------
  } else if (cmpChannel === 'VIDEO' || utmMedium === 'VIDEO') {
    ttDataLayer.add('referrer_type', VIDEO);
    campaignAttributionType = VIDEO;

    // Paid social ---------------------------------------------
  } else if (cmpChannel === 'SOCIAL_PAID' || utmMedium === 'SOCIAL_PAID') {
    ttDataLayer.add('referrer_type', SOCIAL_PAID);
    campaignAttributionType = SOCIAL_PAID;

    // Partnership ---------------------------------------------
  } else if (cmpChannel === 'PARTNERSHIP' || utmMedium === 'PARTNERSHIP') {
    ttDataLayer.add('referrer_type', PARTNERSHIP);
    campaignAttributionType = PARTNERSHIP;

    // Open partners  ---------------------------------------------
  } else if (
    cmpChannel === 'OPEN_PARTNER' ||
    utmMedium === 'OPEN_PARTNER' ||
    'sc_openpartner' in queries
  ) {
    ttDataLayer.add('referrer_type', OPEN_PARTNER);
    campaignAttributionType = OPEN_PARTNER;

    // Intern campaign ---------------------------------------------
  } else if (
    cmpChannel === 'INTERN' ||
    utmMedium === 'INTERN' ||
    'sc_intcmp' in queries
  ) {
    ttDataLayer.add('referrer_type', INTERN_CAMPAIGN);
    ttDataLayer.add('internal_campaign', campaign);
    campaignAttributionType = INTERN_CAMPAIGN;

    // Push notification ---------------------------------------------
  } else if (cmpChannel === 'PUSH_NOTIF' || utmMedium === 'PUSH_NOTIF') {
    ttDataLayer.add('referrer_type', PUSH_NOTIF);
    campaignAttributionType = PUSH_NOTIF;

    // Banner ---------------------------------------------
  } else if (cmpChannel === 'BANNER' || utmMedium === 'BANNER') {
    ttDataLayer.add('referrer_type', BANNER);
    campaignAttributionType = BANNER;

    // Organic social ---------------------------------------------
  } else if (
    cmpChannel === 'SOCIAL_ORGANIC' ||
    utmMedium === 'SOCIAL_ORGANIC'
  ) {
    ttDataLayer.add('referrer_type', SOCIAL_ORGANIC);
    campaignAttributionType = SOCIAL_ORGANIC;

    // Social Network, Natural Search & Others --------------------------------
  } else if (typeof referringDomain !== 'undefined' && referringDomain !== '') {
    // We do not have a value for campaign so we add the referring domain instead with the target page
    const defaultCampaignValueForReferrer = `${referringDomain}|${ttDataLayer
      .get('pageURL')
      .replace(/https?:\/\//, '')}`;

    // Social Network -----------------------------------------------------------
    if (
      referringDomain.indexOf('facebook') > -1 ||
      referringDomain.indexOf('instagram') > -1 ||
      referringDomain.indexOf('twitter') > -1 ||
      referringDomain.indexOf('youtube') > -1 ||
      referringDomain.indexOf('dailymotion') > -1 ||
      referringDomain.indexOf('snapchat') > -1 ||
      referringDomain.indexOf('pinterest') > -1 ||
      referringDomain.indexOf('twitch') > -1 ||
      referringDomain.indexOf('tiktok') > -1 ||
      referringDomain === 't.co' // Twitter's short url
    ) {
      ttDataLayer.add('referrer_type', SOCIAL_NETWORK);
      campaignAttributionType = SOCIAL_NETWORK;
      campaign = defaultCampaignValueForReferrer;

      // Natural Search -----------------------------------------------------------
    } else if (
      referringDomain.indexOf('google') > -1 ||
      referringDomain.indexOf('bing') > -1 ||
      referringDomain.indexOf('yahoo') > -1 ||
      referringDomain.indexOf('qwant') > -1 ||
      referringDomain.indexOf('ask') > -1 ||
      referringDomain.indexOf('duckduckgo') > -1 ||
      referringDomain.indexOf('ecosia') > -1 ||
      referringDomain.indexOf('yandex') > -1 ||
      referringDomain.indexOf('baidu') > -1 ||
      referringDomain.indexOf('aol') > -1 ||
      referringDomain.indexOf('lycos') > -1 ||
      referringDomain.indexOf('wolframalpha') > -1 ||
      referringDomain.indexOf('archive.org') > -1
    ) {
      ttDataLayer.add('referrer_type', NATURAL_SEARCH);
      campaignAttributionType = NATURAL_SEARCH;
      campaign = defaultCampaignValueForReferrer;

      // CANAL+ domains -----------------------------------------------------------
    } else if (referringDomain.indexOf('canal') > -1) {
      ttDataLayer.add('referrer_type', CPLUS_DOMAINS);

      // Other referring domains -----------------------------------------------------------
    } else {
      ttDataLayer.add('referrer_type', OTHER_REF_DOMAINS);
      campaignAttributionType = OTHER_REF_DOMAINS;
      campaign = defaultCampaignValueForReferrer;
    }
  }

  ttDataLayer.add('eVar13', referrer);
  ttDataLayer.add('campaign', campaign);

  if (campaign) {
    const formatedCampaign = {
      campaign,
      campaignType: campaignAttributionType,
      campaignThematic,
      campaignEntity,
    };
    // Store campaign data in localStorage
    setCampaignInLocalStorage(formatedCampaign);
    const { externalAttribution, paidAttribution } = getAttributions();

    store.dispatch({
      'type': 'SET_SOURCE',
      campaign,
      campaignAttributionType,
      campaignEntity,
      campaignThematic,
      externalAttribution,
      paidAttribution,
      referrer,
      partners,
    });

    syncTrackingCampaignToIframe(formatedCampaign);
    // reset campaign data in store after setting it in localStorage
    store.dispatch({ type: 'RESET_CAMPAIGN_DATA' });
  }
};

/*
 * Utils
 */
const extractCampaignDataFromQueryParams = (queryParams) => {
  let campaignData = {};

  /* LEGACY */
  if ('sc_cmpid' in queryParams) {
    campaignData = { 'name': queryParams.sc_cmpid };
  } else if ('clkid' in queryParams) {
    campaignData = { 'name': queryParams.clkid };
  } else if ('cpmid' in queryParams) {
    campaignData = { 'name': queryParams.cpmid };
  } else if ('kwid' in queryParams) {
    campaignData = { 'name': queryParams.kwid };
  } else if ('sc_intcmp' in queryParams) {
    campaignData = { 'name': queryParams.sc_intcmp };
  } else if ('sc_openpartner' in queryParams) {
    campaignData = { 'name': queryParams.sc_openpartner };
  }
  /* END LEGACY */
  // add cmp params if they exist for Amplitude campaign
  if ('cmp_channel' in queryParams) {
    campaignData = { ...campaignData, 'channel': queryParams.cmp_channel };
  }
  if ('cmp_entity' in queryParams) {
    campaignData = { ...campaignData, 'entity': queryParams.cmp_entity };
  }
  if ('cmp_type' in queryParams) {
    campaignData = { ...campaignData, 'type': queryParams.cmp_type };
  }
  if ('cmp_thematic' in queryParams) {
    campaignData = { ...campaignData, 'thematic': queryParams.cmp_thematic };
  }
  if ('cmp_target' in queryParams) {
    campaignData = { ...campaignData, 'target': queryParams.cmp_target };
  }
  if ('cmp_date' in queryParams) {
    campaignData = { ...campaignData, 'date': queryParams.cmp_date };
  }
  if ('cmp_name' in queryParams) {
    campaignData = { ...campaignData, 'name': queryParams.cmp_name };
  }
  if ('cmp_support' in queryParams) {
    campaignData = { ...campaignData, 'support': queryParams.cmp_support };
  }
  if ('cmp_crea' in queryParams) {
    campaignData = { ...campaignData, 'crea': queryParams.cmp_crea };
  }
  if ('cmp_placement' in queryParams) {
    campaignData = { ...campaignData, 'placement': queryParams.cmp_placement };
  }

  // add utm params if they exist for Google Analytics 360 campaign
  if ('utm_campaign' in queryParams && !campaignData.name) {
    campaignData = { ...campaignData, 'name': queryParams.utm_campaign };
  }
  if ('utm_medium' in queryParams) {
    campaignData = { ...campaignData, 'channel': queryParams.utm_medium };
  }
  if ('utm_source' in queryParams) {
    campaignData = { ...campaignData, 'support': queryParams.utm_source };
  }
  if ('utm_content' in queryParams) {
    campaignData = { ...campaignData, 'crea': queryParams.utm_content };
  }
  return campaignData;
};

const addSearchInfo = (ttDataLayer, trackingObject) => {
  // Fill prop1 with search if provided
  if (trackingObject.search && trackingObject.search.keyword) {
    ttDataLayer.add(
      'prop1',
      trackingObject.search.keyword.toLowerCase().trim(),
    );

    // Add result count to prop2
    if (trackingObject.search.count) {
      ttDataLayer.add('prop2', trackingObject.search.count);
    }

    // Add search event
    ttDataLayer.addEvent('event1');
  }
};

export const addLevelInfo = (ttDataLayer, trackingObject) => {
  // Add value for prop4, 5 ... 9 based on level hierarchy
  if (trackingObject.level1) {
    const hierarchicalArray = [
      trackingObject.level2,
      trackingObject.level3,
      trackingObject.level4,
      trackingObject.level5,
    ];
    let currentIndex = 4; // We start with prop4 because prop1, 2 and 3 are already used
    let nextIndex = 5;
    ttDataLayer.add(
      'prop4',
      `${ttDataLayer.get('channel')} - ${trackingObject.level1}`,
    );

    hierarchicalArray.forEach((item) => {
      if (item) {
        ttDataLayer.add(
          `prop${nextIndex}`,
          `${ttDataLayer.get(`prop${currentIndex}`)} - ${item}`,
        );
        currentIndex += 1;
        nextIndex += 1;
      }
    });

    ttDataLayer.add(
      `prop${nextIndex}`,
      `${ttDataLayer.get(`prop${currentIndex}`)} - ${trackingObject.name}`,
    ); // Last prop always contains the pageName
    ttDataLayer.add('pageName', ttDataLayer.get(`prop${nextIndex}`));
  } else {
    // No level1
    ttDataLayer.add(
      'prop4',
      `${ttDataLayer.get('channel')} - ${trackingObject.name}`,
    );
    ttDataLayer.add('pageName', ttDataLayer.get('prop4'));
  }
};

const addPassInfo = (store, ttDataLayer) => {
  // It's assumed that pass is loaded before this script since it's in async mode and called after waitForPassJSON...
  if (window.passJSON && window.passJSON !== null) {
    const micros = passJSON.microEligibility || '';

    // if the user is connected (has a subscriberId)
    if (isPassAuthenticated()) {
      ttDataLayer.add('login_status', 'Logged-in');
      ttDataLayer.add('eVar16', 'Identifie');
      ttDataLayer.add('eVar36', passJSON.partner ? passJSON.partner : 'CANAL'); // Account's source (FREE, Orange, Facebook, Canal...)
      ttDataLayer.add('preferences_reco', passJSON.collectUserData.toString()); // Only valid for logged-in user
      ttDataLayer.add(
        'customer_type',
        (passJSON.subscriberType && passJSON.subscriberType === 'PRO') ||
          micros.indexOf('R_ABO_PRO') > -1
          ? 'B2B'
          : 'B2C',
      );
    } else {
      ttDataLayer.add('eVar19', 'Aucun');
      ttDataLayer.add('login_status', 'Not-logged-in');
      ttDataLayer.add('eVar16', 'Inconnu');
    }

    // Add the id_advertising and id_analytics if defined by passJSON
    if (
      typeof passJSON.id_advertising !== 'undefined' &&
      passJSON.id_advertising !== ''
    ) {
      ttDataLayer.add('id_advertising', passJSON.id_advertising);
    }

    if (
      typeof passJSON.id_analytics !== 'undefined' &&
      passJSON.id_analytics !== ''
    ) {
      ttDataLayer.add('id_analytics', passJSON.id_analytics);
    }

    const offerMatches = micros.match(/.*(OFFER:\[([^\]]+)\])/) || '';
    const offers =
      typeof offerMatches[2] !== 'undefined' ? offerMatches[2] : '';
    ttDataLayer.add('eVar20', offers !== '' ? offers : 'Unknown');

    if (micros.indexOf('O_ABO') >= 0 || passJSON.isSubscriber === true) {
      ttDataLayer.add('subscriber_status', 'Subscriber');
      ttDataLayer.add('eVar17', 'Abonne'); // legacy

      // With or without commitment subscription
      if (micros.indexOf('OAE') >= 0) {
        ttDataLayer.add('eVar57', 'With commitment (OAE)');
      } else if (micros.indexOf('OSE') >= 0) {
        ttDataLayer.add('eVar57', 'Without commitment (OSE)');
      }
    } else if (isPassAuthenticated()) {
      ttDataLayer.add('subscriber_status', 'Prospect');
      ttDataLayer.add('eVar17', 'Prospect'); // legacy
    } else {
      // Should be an error case
      ttDataLayer.add('subscriber_status', 'Unknown');
      ttDataLayer.add('eVar17', 'Inconnu'); // legacy
    }
  } else {
    // If there is no passJSON, user is not logged-in
    ttDataLayer.add('login_status', 'Not-logged-in');
    ttDataLayer.add('eVar16', 'Inconnu');
    ttDataLayer.add('subscriber_status', 'Unknown');
    ttDataLayer.add('eVar17', 'Inconnu');
  }
};

const addBrowserInfo = (ttDataLayer) => {
  ttDataLayer.add('server', window.location.hostname);

  const uap = new UAParser();
  // Since Apple froze its user agent in version 10.15.7, we haven't yet find a reliable solution that can be derived from
  // only the user agent data (as in the Chrome Dev Tools case). However, if we are using a real browser in iPad
  // we use an additional check for iPad-specific browser features:
  const uaparser = uap.getResult().withFeatureCheck();

  // userAgent information
  ttDataLayer.add('browser_name', uaparser.browser.name);
  ttDataLayer.add('browser_version', uaparser.browser.version);
  ttDataLayer.add('os_name', uaparser.os.name);
  ttDataLayer.add('os_version', uaparser.os.version);
  ttDataLayer.add('device_name', uaparser.device.model || '');

  let deviceBrand = uaparser.device.vendor;
  let deviceCategory = uaparser.device.type;

  // Fallback for device brand if not detected by ua-parser-js
  if (!deviceBrand) {
    deviceBrand = 'unknown';
    if (uaparser.os && uaparser.os.name) {
      const osName = uaparser.os.name.toLowerCase();
      if (osName.includes('mac') || osName.includes('ios')) {
        deviceBrand = 'Apple';
      } else if (osName.includes('windows')) {
        deviceBrand = 'Microsoft';
      } else if (osName.includes('android')) {
        deviceBrand = 'Google';
      } else if (osName.includes('linux')) {
        deviceBrand = 'Linux';
      }
    }
  }

  // Fallback for device category if not detected by ua-parser-js
  if (!deviceCategory) {
    const viewportWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );

    // Retrieve and normalize the operating system name
    let osName = '';
    if (uaparser.os && uaparser.os.name) {
      osName = uaparser.os.name.toLowerCase();
    }

    // Determine device category using OS information
    if (
      osName.includes('mac') ||
      osName.includes('windows') ||
      osName.includes('linux')
    ) {
      // Assume desktop for macOS, Windows, and Linux devices
      deviceCategory = 'desktop';
    } else {
      // Use viewport width for other operating systems
      if (viewportWidth <= 640) {
        deviceCategory = 'mobile';
      } else if (viewportWidth <= 1024) {
        deviceCategory = 'tablet';
      } else {
        deviceCategory = 'desktop';
      }
    }
  }

  ttDataLayer.add('device_brand', deviceBrand);
  ttDataLayer.add('device_category', deviceCategory);
};

const addCustomInfo = (store, ttDataLayer) => {
  const state = store.getState();

  // Add attribution info
  if (
    state.source.paidAttribution?.campaign &&
    state.source.paidAttribution?.campaignType
  ) {
    ttDataLayer.add(
      'paid_attribution_campaign',
      state.source.paidAttribution.campaign,
    );
    ttDataLayer.add(
      'paid_attribution_type',
      state.source.paidAttribution.campaignType,
    );
    ttDataLayer.add(
      'paid_attribution_entity',
      state.source.paidAttribution.entity,
    );
    ttDataLayer.add(
      'paid_attribution_thematic',
      state.source.paidAttribution.thematic,
    );
  }

  if (
    state.source.externalAttribution?.campaign &&
    state.source.externalAttribution?.campaignType
  ) {
    ttDataLayer.add(
      'external_attribution_campaign',
      state.source.externalAttribution.campaign,
    );
    ttDataLayer.add(
      'external_attribution_type',
      state.source.externalAttribution.campaignType,
    );
    ttDataLayer.add(
      'external_attribution_entity',
      state.source.externalAttribution.entity,
    );
    ttDataLayer.add(
      'external_attribution_thematic',
      state.source.externalAttribution.thematic,
    );
  }

  // Add resolution info
  const browserHeight = window.innerHeight; // Real available space (window)
  const browserWidth = window.innerWidth; // Real available space (window)
  const resolution = `${window.screen.width}x${window.screen.height}`; // Screen resolution

  ttDataLayer.add('browser_height', browserHeight);
  ttDataLayer.add('browser_width', browserWidth);
  ttDataLayer.add('device_resolution', resolution);

  ttDataLayer.add('country', state.config.zone); // Legacy
  ttDataLayer.add('app_zone', state.config.zone);

  // AB TESTING
  // Is there a param that overrides the population in cookie?
  // Sometimes, the user jumps from a website to another and we cannot store in cookie so we use parameter in url
  // Does the cookie exist and have a valid value? There is a cookie sync from the iframe that is pushed to the store.
  // That's why we check also from the store. It's used for compute conversion rate when the user jumps from a website to another.
  const abTestingPopulation =
    getQueryParam('ab_test_pop') ||
    readCookie(ABTEST_COOKIE_NAME) ||
    store.getState().abTestingPopulation;
  if (Number.parseInt(abTestingPopulation, 10) >= 0) {
    ttDataLayer.add('ab_test_population', abTestingPopulation);
    store.dispatch({ 'type': 'SET_AB_TESTING', abTestingPopulation });
  }

  // Duplication of the abTestingPopulation cookie used on boutique.canalplus.com and client.canalplus.com
  // to not mix AB test values conducted on these websites and on any other.
  // TEMPORARY solution because of Amplitude mix-up for these values, the data team are looking for a better one for the future
  const abTestingPopulationStore =
    readCookie(ABTEST_STORE_COOKIE_NAME) ||
    store.getState().abTestingPopulationStore;
  if (Number.parseInt(abTestingPopulationStore, 10) >= 0) {
    ttDataLayer.add('ab_test_population_store', abTestingPopulationStore);
    store.dispatch({
      'type': 'SET_AB_TESTING_STORE',
      abTestingPopulationStore,
    });
  }

  // Generate a number between 0 and 100 to downsample if needed (ex: select only event from 0 to 50 for half of them)
  ttDataLayer.addSampling();

  // Copy pageName in eVar10 and add event3 to event's list if pageView
  if (ttDataLayer.get('pageName')) {
    ttDataLayer.add('eVar10', ttDataLayer.get('pageName'));
    ttDataLayer.addEvent('event3');
  }

  ttDataLayer.add('preferences_analytics', state.statsAllowed.toString());
  ttDataLayer.add('preferences_ads', state.adsAllowed.toString());

  // LEGACY: we receive purchased products with this format: "categories;product name;quantity;price"
  // ex: SSEngag;ESS_CS100NFX;1;34.9
  // So we want to split them
  if (ttDataLayer.get('products')) {
    const productArray = ttDataLayer.get('products').split(';');
    if (productArray.length > 3) {
      ttDataLayer.add('product_category', productArray[0]);
      ttDataLayer.add('product_code', productArray[1]);
      ttDataLayer.add('product_price', Number.parseFloat(productArray[3]));
    }
  }
};
