// @ts-check
import {
  COOKIE_NAME_ADS,
  COOKIE_NAME_ANONYMOUS,
  COOKIE_NAME_DEVICE_ID,
  COOKIE_NAME_SESSION_ID,
  COOKIE_NAME_STATS,
  IFRAME_ID,
  IFRAME_PATH_LOCAL,
  IFRAME_PATH_PROD,
} from '../PrivacyManager/Constants';
import {
  cleanCookies,
  extractDeviceInfos,
  getCookie,
  getDataDomain,
  isPassAuthenticated,
  isThirdPartyWebsite,
} from '../Utils';
import { handlePopinDisplay } from './ReceiveFromIframe';

const handleReceiveMessageFromIframe = (
  store,
  iframeHost,
  callbackAfterCookieSync,
) => {
  // Listen for message from the iframe and initialize data
  window.addEventListener('message', (event) => {
    if (event.origin === iframeHost && !__LOCAL__) {
      return;
    }

    const {
      abTestingPopulation,
      abTestingPopulationStore,
      adsAllowed,
      anonymousAllowed,
      campaign,
      campaignType,
      externalAttribution,
      isNewSession,
      paidAttribution,
      pmSessionId,
      pmVID,
      sessionId,
      statsAllowed,
      type,
    } = event.data;

    let { displayPopin } = event.data;

    // We need to set a flag in the dataLayer to add event attributes for the first event in the session
    // In standalone mode, the dataLayer is not available
    if (
      event.data &&
      typeof ttDataLayer !== 'undefined' &&
      (type === 'cookieSync' || type === 'updateSessionId')
    ) {
      if (isNewSession) {
        ttDataLayer.setFirstEventSinceSessionStart();
      } else {
        ttDataLayer.unsetFirstEventSinceSessionStart();
      }
    }

    /* RECEIVE: COOKIE SYNC */
    if (event.data && type === 'cookieSync') {
      const isThirdParty = isThirdPartyWebsite();

      // If we are on a third party website,
      // display the popin according to the cookies stored on the host domain
      // and update the store with the right value from cookies
      const data = {
        statsAllowed: isThirdParty
          ? getCookie(COOKIE_NAME_STATS) === 'true'
          : statsAllowed,
        adsAllowed: isThirdParty
          ? getCookie(COOKIE_NAME_ADS) === 'true'
          : adsAllowed,
        anonymousAllowed: isThirdParty
          ? getCookie(COOKIE_NAME_ANONYMOUS) !== 'false'
          : anonymousAllowed,
      };

      if (isThirdParty) {
        displayPopin =
          !getCookie(COOKIE_NAME_STATS) &&
          !getCookie(COOKIE_NAME_ADS) &&
          !getCookie(COOKIE_NAME_ANONYMOUS);
      }

      const { deviceId, authenticatedFlag, trackingKey } =
        extractDeviceInfos(pmVID);

      // Init all variables from the iframe
      store.dispatch({
        type: 'COOKIE_SYNC',
        data,
        VID: deviceId,
        trackingKey,
        authenticatedFlagFromCookie: authenticatedFlag,
        sessionId: pmSessionId,
        campaign,
        campaignType,
        abTestingPopulation,
        abTestingPopulationStore,
        externalAttribution,
        paidAttribution,
      });

      // Listen to URL changes to display the popin after navigating from the cookies policy page
      let lastUrl = window.location.href;
      const urlChangeConfig = { subtree: true, childList: true };
      const urlChange = new MutationObserver(() => {
        const url = window.location.href;
        if (url !== lastUrl) {
          lastUrl = url;
          handlePopinDisplay(store, displayPopin, urlChange);
        }
      });

      urlChange.observe(document, urlChangeConfig);

      if (document.readyState === 'complete') {
        handlePopinDisplay(store, displayPopin, urlChange);
      } else {
        const currentOnload = window.onload;
        window.onload = () => {
          if (typeof currentOnload === 'function') {
            currentOnload();
          }

          handlePopinDisplay(store, displayPopin, urlChange);
        };
      }

      callbackAfterCookieSync();
    } else if (event.data && type === 'updateSessionId' && sessionId !== '') {
      /* RECEIVE: UPDATE SESSION ID */
      store.dispatch({ type: 'UPDATE_SESSION_ID', sessionId });
    }
  });
};

export const addIframeForCookieSync = (store, callbackAfterCookieSync) => {
  const state = store.getState();
  const campaignType = state.source.campaignType;
  const campaign = state.source.campaign;
  const campaignEntity = state.source.campaignEntity;
  const campaignThematic = state.source.campaignThematic;
  /* Add iframe for cookie sync */
  // eslint-disable-next-line react/iframe-missing-sandbox
  const cookieSyncIframe = document.createElement('iframe');
  const domain = getDataDomain(state.config.brand);
  const iframeSrc = __LOCAL__
    ? IFRAME_PATH_LOCAL
    : `https://${domain}${IFRAME_PATH_PROD}`;
  let params = '';

  // IE 11 doesn't support this
  try {
    params = new URLSearchParams();
    if (campaignType && campaign) {
      params.append('campaign', encodeURI(campaign));
      params.append('campaignType', encodeURI(campaignType));
      params.append('campaignEntity', encodeURI(campaignEntity));
      params.append('campaignThematic', encodeURI(campaignThematic));
    }
    if (state.abTestingPopulation) {
      params.append('abTestPop', state.abTestingPopulation);
    }
    if (state.abTestingPopulationStore) {
      params.append('abTestPopStore', state.abTestingPopulationStore);
    }
  } catch (e) {
    console.log(e);
  }

  cookieSyncIframe.id = IFRAME_ID;
  cookieSyncIframe.src = `${iframeSrc}?${params}`;
  cookieSyncIframe.style.cssText =
    'border: 0px; width: 0px; height: 0px; display: none; position: absolute; clip: rect(0px 0px 0px 0px);';
  cookieSyncIframe.setAttribute('aria-hidden', true);
  document.body.appendChild(cookieSyncIframe);

  store.dispatch({ type: 'ADD_IFRAME' }); // kind of log, no store diff
  handleReceiveMessageFromIframe(store, domain, callbackAfterCookieSync);
};

export const setStatsOptOut = (store, statsAllowed) => {
  store.dispatch({ type: 'SET_STATS_PREF', statsAllowed: !!statsAllowed });
};

export const setAdsOptOut = (store, adsAllowed) => {
  store.dispatch({ type: 'SET_ADS_PREF', adsAllowed: !!adsAllowed });
};

export const setAnonymous = (store, anonymousAllowed) => {
  store.dispatch({
    type: 'SET_ANONYMOUS_PREF',
    anonymousAllowed: !!anonymousAllowed,
  });
};

export const setStatsAndAnonymousOptOut = (store, statsAllowed) => {
  setStatsOptOut(store, statsAllowed);
  if (statsAllowed) {
    setAnonymous(store, true);
  }
};

export const updateSessionId = (store, sessionId) => {
  store.dispatch({ type: 'UPDATE_SESSION_ID', sessionId });
};

export const updateDeviceKey = (store, deviceKey) => {
  store.dispatch({ type: 'UPDATE_DEVICE_KEY', deviceKey });
};

export const syncUserPrefWithIframe = (store) => {
  const state = store.getState();

  const data = {
    type: 'cookieSync',
    statsAllowed: state.statsAllowed,
    adsAllowed: state.adsAllowed,
    anonymousAllowed: state.anonymousAllowed,
    deviceId: state.VID,
    sessionId: state.sessionId,
    trackingKey: state.trackingKey,
    authenticatedFlag: isPassAuthenticated() ? '1' : '0',
  };

  if (!state.anonymousAllowed) {
    cleanCookies([COOKIE_NAME_DEVICE_ID, COOKIE_NAME_SESSION_ID]);
  }

  store.dispatch({ type: 'SYNC_PREF_WITH_IFRAME' });
  document.getElementById(IFRAME_ID).contentWindow.postMessage(data, '*');
};

// Extend or renew the user session when the page is visible every X minutes
// Use case: a user is playing a video for 2 hours, we need to extend his session
export const handleExtendOrRenewSession = (store) => {
  const state = store.getState();

  if (typeof document.hidden !== 'undefined') {
    setInterval(
      () => {
        if (!document.hidden && state.anonymousAllowed) {
          extendOrRenewSessionAction();
        }
      },
      5 * 60 * 1000 /* every 5 minutes */,
    );

    // Use case: when the user closes their laptop (standby mode)
    // The next morning, when they open it again, we need to renew their sessionId and not use the previous one
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden && state.anonymousAllowed) {
        extendOrRenewSessionAction();
      }
    });
  }
};

export const extendOrRenewSessionAction = () => {
  const iframe = document.getElementById(IFRAME_ID);
  if (iframe) {
    iframe.contentWindow.postMessage({ type: 'extendOrRenewSession' }, '*');
  }
};

export const updateDeviceIdCookieAction = (
  deviceId,
  authenticatedFlag,
  trackingKey,
) => {
  const iframe = document.getElementById(IFRAME_ID);
  if (iframe) {
    iframe.contentWindow.postMessage(
      {
        type: 'updateDeviceIdCookie',
        deviceId,
        authenticatedFlag,
        trackingKey,
      },
      '*',
    );
  }
};
