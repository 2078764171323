import {
  AFFILIATION,
  DISPLAY,
  EMAIL,
  IFRAME_ID,
  NATURAL_SEARCH,
  OPEN_PARTNER,
  OTHER_REF_DOMAINS,
  PAID_SEARCH,
  PARTNERSHIP,
  SMS,
  SOCIAL_NETWORK,
  SOCIAL_PAID,
  VIDEO,
} from '../PrivacyManager/Constants';

// Attribution types constants
const EXTERNAL_ATTRIBUTION_TYPES = [
  DISPLAY,
  AFFILIATION,
  PAID_SEARCH,
  OPEN_PARTNER,
  SOCIAL_NETWORK,
  NATURAL_SEARCH,
  EMAIL,
  OTHER_REF_DOMAINS,
  PARTNERSHIP,
  SMS,
  VIDEO,
  SOCIAL_PAID,
];
const PAID_ATTRIBUTION_TYPES = [
  DISPLAY,
  AFFILIATION,
  PAID_SEARCH,
  PARTNERSHIP,
  SOCIAL_PAID,
  VIDEO,
];

// Attribution expiration constants
const thirtyDays = 1000 * 60 * 60 * 24 * 30;
const tenDays = 1000 * 60 * 60 * 24 * 10;

/** Campaign Manager
 * Stores campaign information in localStorage, ensuring that the same campaign isn't stored multiple times consecutively.
 *
 * Attribution rules:
 * 1. Campaigns are stored in localStorage as a Last In, First Out (LIFO) array.
 * 2. Only the first campaign is used for attribution.
 * 3. Campaigns expire after 30 days.
 * @param {Object} formatedCampaign campaign, campaignType, campaignThematic, campaignEntity
 */
export function setCampaignInLocalStorage({
  campaign,
  campaignType,
  campaignThematic,
  campaignEntity,
}) {
  // Ensure that the campaign is not empty
  if (!campaign) {
    return;
  }

  // Retrieve the existing trackingCampaigns from localStorage (if any)
  let existingCampaigns = [];
  const storedCampaigns = localStorage.getItem('trackingCampaign');

  // Parse stored campaigns from localStorage
  if (storedCampaigns) {
    try {
      existingCampaigns = JSON.parse(storedCampaigns);
      if (!Array.isArray(existingCampaigns)) {
        existingCampaigns = []; // Reset to empty array if data is not an array
      }
    } catch (error) {
      console.error('Error parsing trackingCampaign from localStorage:', error);
      existingCampaigns = [];
    }
  }

  // Check if the first campaign in the array matches the new one
  const firstCampaign =
    existingCampaigns.length > 0 ? existingCampaigns[0] : null;

  const isSameAsFirstCampaign =
    firstCampaign && firstCampaign.campaign === campaign;

  // If the first campaign is the same, update only the timestamp
  if (isSameAsFirstCampaign) {
    existingCampaigns[0].timestamp = Date.now();
  } else {
    // Create the new campaign object if it's not the same as the first one
    const newCampaign = {
      ...(campaign && { campaign }),
      ...(campaignType && { campaignType }),
      ...(campaignThematic && { thematic: campaignThematic }),
      ...(campaignEntity && { entity: campaignEntity }),
      timestamp: Date.now(), // Add a timestamp for expiration tracking
    };

    existingCampaigns.unshift(newCampaign); // Add the new campaign to the beginning of the array
  }

  // Filter out campaigns older than 30 days and limit to the most recent 10
  const trackingCampaignFiltered = existingCampaigns.filter((item) => {
    return Date.now() - item.timestamp < thirtyDays;
  });
  const trackingCampaignSliced = trackingCampaignFiltered.slice(0, 10);

  // Update localStorage with the modified campaigns
  localStorage.setItem(
    'trackingCampaign',
    JSON.stringify(trackingCampaignSliced),
  );
}

/** Retrieves the stored tracking campaigns from localStorage.
 *
 * This helper ensures that the data is a valid array and catches any errors
 * during parsing.
 *
 * @returns {Array} An array of campaign objects from localStorage, or an empty array if none are found or if parsing fails.
 */
function getCampaignFromLocalStorage() {
  try {
    const storedCampaigns = localStorage.getItem('trackingCampaign');
    const campaigns = storedCampaigns ? JSON.parse(storedCampaigns) : [];

    // Ensure it's an array
    if (Array.isArray(campaigns)) {
      return campaigns;
    } else {
      console.warn(
        'trackingCampaign in localStorage is not an array:',
        campaigns,
      );
      return [];
    }
  } catch (error) {
    console.error(
      'Error retrieving trackingCampaign from localStorage:',
      error,
    );
    return [];
  }
}

/** Attribution Manager
 * Calculates the external and paid attributions based on the campaigns stored in localStorage.
 *
 * Attribution rules:
 * 1. External attribution: Last external source (not Intern campaign or Email) within 30 days.
 * 2. Paid attribution: Any external attribution source minus Social Network and Natural Search (free sources) within 10 days.
 * 3. Iteration over campaigns is done in reverse (LIFO) to respect recent campaign attribution.
 *
 * @returns {Object} An object containing external and paid attributions.
 */
export function getAttributions() {
  let externalAttribution = {};
  let paidAttribution = {};

  // Retrieve trackingCampaign from localStorage
  const trackingCampaign = getCampaignFromLocalStorage();

  // If trackingCampaign is empty, return default values
  if (trackingCampaign.length === 0) {
    console.warn('No valid trackingCampaign found in localStorage.');
    return { externalAttribution, paidAttribution };
  }

  // Iterate over the campaigns array in reverse (LIFO), starting from the most recent
  for (let i = trackingCampaign.length - 1; i >= 0; i -= 1) {
    const campaign = trackingCampaign[i];

    // Check for external attribution: Last external source within 30 days
    if (
      EXTERNAL_ATTRIBUTION_TYPES.includes(campaign.campaignType) &&
      Date.now() - campaign.timestamp < thirtyDays
    ) {
      // Keep only the most recent external attribution
      if (
        !externalAttribution.timestamp ||
        campaign.timestamp > externalAttribution.timestamp
      ) {
        externalAttribution = campaign;
      }
    }

    // Check for paid attribution: External attribution minus free sources within 10 days
    if (
      PAID_ATTRIBUTION_TYPES.includes(campaign.campaignType) &&
      Date.now() - campaign.timestamp < tenDays
    ) {
      // Keep only the most recent paid attribution
      if (
        !paidAttribution.timestamp ||
        campaign.timestamp > paidAttribution.timestamp
      ) {
        paidAttribution = campaign;
      }
    }
  }

  return { externalAttribution, paidAttribution };
}

/**
 * Synchronizes the tracking campaign data with the iframe by sending a message
 * containing the trackingCampaign array from localStorage.
 *
 * This function retrieves the `trackingCampaign` data from localStorage,
 * then locates the iframe element by ID. If the iframe is present, it sends
 * a postMessage containing the trackingCampaign to the iframe for cross-domain
 * synchronization.
 *
 * Usage:
 * This function should be called whenever the trackingCampaign data needs to
 * be updated in the iframe to ensure both the iframe and main domain have consistent data.
 * @param {Object} trackingCampaign - The trackingCampaign data to send to the iframe.
 */
export const syncTrackingCampaignToIframe = (trackingCampaign) => {
  const iframe = document.getElementById(IFRAME_ID);
  if (iframe) {
    iframe.contentWindow.postMessage(
      {
        type: 'updateTrackingCampaign',
        trackingCampaign,
      },
      '*',
    );
  }
};
